import './App.css';
// import Footer from './pages/Footer';
import Nav from './pages/Nav';
function App() {
  return (
    <div className="mt-20 md:mt-24">
      <Nav/>
     {/* <div className=''>
     <Footer/>
     </div> */}
    </div>
  );
}

export default App;